// react
import React from "react";
import {inject, observer} from "mobx-react";
import {AiOutlineBarChart} from "react-icons/ai";
import {Button, Dropdown} from "react-bootstrap";
import {NavigateFunction} from "react-router";
import {Tooltip} from "react-tooltip";

// local
import {
	AppMenu,
	Footer,
	Header,
	Loading,
	PopupMessage
} from "../components";
import {
	FreshdeskCompany,
	FreshdeskTicket,
	getDaysInMonth,
	PageProps,
	withNavigation,
	CURRENT_YEAR,
	CURRENT_MONTH,
	FRESHDESK_PAGE_SIZE,
	REGEX_HTML_TAG,
	system,
	webservice
} from "../types";
import {IoReload} from "react-icons/io5";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	date: Date
	onChangeDate: (year: number, month: number) => void,
	onReloadList: () => void
}

const Subheader = (props: SubheaderProps) => {

	// setup
	const {date, onReloadList} = props;
	const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
	const endDate = new Date(CURRENT_YEAR, CURRENT_MONTH, 1, 0, 0, 0);
	let startDate = new Date(CURRENT_YEAR-1, CURRENT_MONTH, 1, 0, 0, 0);

	const dateId = date.toLocaleString('en-US', options);

	const eDates = [];
	while (startDate < endDate) {
		// setup
		const id = startDate.getFullYear() + '-' + startDate.getMonth();
		const name = startDate.toLocaleString('en-US', options);

		// render
		eDates.push(
			<Dropdown.Item id={id} key={id} eventKey={id}>
				{name}
			</Dropdown.Item>
		);

		// Increment the date.
		startDate = (startDate.getMonth() === 11) ?
			new Date(startDate.getFullYear() + 1, 0, 1, 23, 59, 59) :
			new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1, 0, 0, 0);
	}

	function onChangeDate(eventKey: any) {
		// setup
		const date = eventKey.split('-');

		// Update the settings.
		props.onChangeDate(parseInt(date[0]), parseInt(date[1]));
	}

	function onReports() {
		// setup
		const {navigate} = props;

		// Go to the engagements page.
		navigate("/reports");
	}

	return (
		<div className="subheader">

			<Dropdown onSelect={onChangeDate}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{dateId}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eDates}
				</Dropdown.Menu>
			</Dropdown>

			<Button className='navigate-report' variant='secondary' size="sm" onClick={onReports}>
				Back to Reporting
			</Button>

		</div>
	);
}

/**
 * EngagementsPage
 */

interface EngagementsProps extends PageProps {
}
interface EngagementsState {
	startDate: Date,
	endDate: Date,
	// companies
	companiesLoaded: boolean,
	companies: FreshdeskCompany[],
	// tickets
	ticketsLoaded: boolean,
	tickets: FreshdeskTicket[],
	ticketPage: number,
	ticketCount: number,
	referrals: number,
	// export
	exportTitle: string | null,
	exportMessage: string | null
}

@inject("user")
@inject("client")
@observer
class EngagementsPageComponent extends React.Component<EngagementsProps, EngagementsState> {

	loading = false;

	constructor(props: EngagementsProps) {
		super(props);

		// Determine the default start/end date (this month).
		const startDate = new Date(CURRENT_YEAR, CURRENT_MONTH-1, 1, 0, 0, 0);
		const endDate = new Date(CURRENT_YEAR, CURRENT_MONTH-1, getDaysInMonth(CURRENT_YEAR, CURRENT_MONTH-1)-1, 23, 59, 59);

		const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
		const exportTitle = 'Export Data: '+startDate.toLocaleString('en-US', options);

		this.state = {
			startDate,
			endDate,
			// companies
			companiesLoaded: false,
			companies: [],
			// tickets
			ticketsLoaded: false,
			tickets: [],
			ticketPage: 0,
			ticketCount: 0,
			referrals: 0,
			// export
			exportTitle,
			exportMessage: null
		}

		// Register the callbacks.
		this.onChangeDate = this.onChangeDate.bind(this);
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onExportList = this.onExportList.bind(this);
		this.onGoBack = this.onGoBack.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onPagePrev = this.onPagePrev.bind(this);
		this.onPageNext = this.onPageNext.bind(this);
		this.onReloadList = this.onReloadList.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {companies, startDate, endDate} = this.state;

		// freshdesk
		if (!this.loading) {
			this.loading = true;
			this.fetchCompanies(companies,1);
			this.fetchTickets(startDate, endDate, 0, [], 0);
		}
	}

	fetchCompanies(companies: FreshdeskCompany[], page: number) {
		// See: https://developers.freshdesk.com/api/
		const that = this;

		const params = {page};
	}

	fetchTickets(startDate: Date, endDate: Date, ticketPage: number, tickets1: FreshdeskTicket[], referrals: number) {
		// setup
		const that = this;
		const start = (startDate.toISOString().split('T'))[0];
		const end = (endDate.toISOString().split('T'))[0];
		const query = `created_at:> '${start}' AND created_at:< '${end}'`;
	}

	onChangeDate(year: number, month: number) {
		// Determine the new start/end dates.
		const startDate = new Date(year, month, 1, 0, 0, 0);
		const endDate = new Date(year, month, getDaysInMonth(year, month)-1, 23, 59, 59);

		const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
		const exportTitle = 'Export Data: '+startDate.toLocaleString('en-US', options);

		// Retrieve the new information.
		this.setState({startDate, endDate, exportTitle, ticketsLoaded: false, ticketPage: 0, ticketCount: 0});
		this.fetchTickets(startDate, endDate, 0, [], 0);
	}

	onDismissPopup() {
		// setup
		this.setState({exportMessage: null});
	}

	onGoBack() {
		// setup
		const {navigate} = this.props;
		navigate('/reports');
	}

	onExportList = async () => {
		// setup
		this.setState({exportMessage: "Loading"});

		const {tickets, startDate, companies} = this.state;
		const year = startDate.getFullYear();
		const month = startDate.getMonth() + 1;

		const params = new FormData();
		params.append('wstoken', system.apiKey);
		params.append('wsfunction', 'mdl_add_activities');
		params.append('moodlewsrestformat', 'json');

		params.append('source', 'freshdesk');
		params.append('year', year.toString());
		params.append('month', month.toString());

		for (let i = 0; i < tickets.length; i++) {
			// Normalize the data.
			const item = tickets[i];
			const company = companies.find(c => c.id === item.company_id);
			let company_id = company ? (company.alias ? company.alias : company.name) : 'Blunovus';

			// Add to the post.
			if (company && company.name && item.type && item.category) {
				params.append('activities[]', JSON.stringify({
					company_id,
					activity_id: item.id,
					activity_type: 'care',
					activity_category: item.category ? item.category : 'Employee',
					activity_name: item.type ? item.type : 'Other',
					activity_title: item.type? item.type : 'Other',
					engagements: 1,
					time: 60
				}));
			}
		}

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', params);
		const data = results.data;
		const exportMessage = (data && data.success) ?
			`Completed exporting ${data.count} tickets.` :
			`Error exporting ${tickets.length} tickets.`;
		this.setState({exportMessage});
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onPageNext() {
		// setup
		const {ticketPage} = this.state;

		// Go to the next page.
		this.setState({ticketPage: ticketPage + 1});
	}

	onPagePrev() {
		// setup
		const {ticketPage} = this.state;

		// Go to the previous page.
		this.setState({ticketPage: ticketPage - 1});
	}

	onReloadList() {
		// setup
		const {startDate, endDate} = this.state;

		// freshdesk
		this.setState({ticketsLoaded: false, ticketPage: 0});
		this.fetchTickets(startDate, endDate, 0, [], 0);
	}

	render() {
		// setup
		const {navigate, client, user} = this.props;
		const {startDate, exportTitle, exportMessage} = this.state;

		// render
		return (
			<div className="page">
				<Tooltip id="lct-engagement-tooltip"/>
				<Header client={client}>
					<AppMenu user={user} onLogout={this.onLogout}/>
				</Header>
				<Subheader
					navigate={navigate}
					date={startDate}
					onChangeDate={this.onChangeDate}
					onReloadList={this.onReloadList}
				/>

				<div className="scroll-y-full">
					{this.renderEngagements()}
					<hr/>
				</div>

				<PopupMessage
					show={!!exportMessage}
					title={exportTitle}
					body={exportMessage}
					onClose={this.onDismissPopup}
				/>

				<Footer/>
			</div>
		);
	}

	renderEngagements() {
		// setup
		const {
			companies, tickets, companiesLoaded,
			ticketsLoaded, ticketPage, ticketCount,
			exportTitle, referrals
		} = this.state;

		// render
		return (
			<div className='section'>
				<div className='section-header'>
					<AiOutlineBarChart className="section-icon" size={28}/>
					Care Center Engagements
					<IoReload
						className="section-reload"
						data-tooltip-id="lct-engagement-tooltip"
						data-tooltip-content="Click to refresh list"
						data-tooltip-place='right'
						size={18}
						onClick={this.onReloadList}
					/>
					<Button className="navigate-export" variant='secondary' size="sm" onClick={this.onExportList}>
						{exportTitle}
					</Button>
				</div>

				{!companiesLoaded || !ticketsLoaded ?
					<Loading/> :
					<></>
				}
			</div>
		);
	}

}

export const EngagementsPage = withNavigation(EngagementsPageComponent);
