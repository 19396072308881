// react
import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {Provider} from "mobx-react"
//import ReactGA from 'react-ga';

// local
import "./types/client.css"
import "./components/components.css";
import "./pages/pages.css";
import 'react-tooltip/dist/react-tooltip.css';
import './App.css';

import {defaultClient, clients, Client, defaultUser, User, system} from "./types";
import {
    EngagementsPage,
    ForgotPassword,
    LoginPage,
    ReportsPage,
    CoursesPage,
    SupportPage,
    StudentPage,
    SurveyPage
} from './pages';
import {Loading} from "./components";
//ReactGA.initialize(GOOGLE_TRACKING_ID);

// freshdesk
// See: https://github.com/arjunkomath/node-freshdesk-api

//window.Buffer = window.Buffer || require("buffer").Buffer;
//export const Freshdesk = require("freshdesk-api");
//export const frApi = new Freshdesk("https://blunovus.freshdesk.com", "ljgL61qbzcvkhFAYk5bv");
//export const frApi = new Freshdesk("https://aaadrivingcourse.freshdesk.com", "ufwA4KxJFdk9GiDNfLdj");

/**
 * App
 */

interface IAppProps {
}

interface IAppState {
    loaded: boolean,
    user: User,
    client: Client
}

class App extends React.Component<IAppProps, IAppState> {

    constructor(props: IAppProps) {
        super(props);

        // Initialize the mobx globals.
        this.state = {
            loaded: false,
            user: defaultUser,
            client: defaultClient
        }
    }

    componentDidMount = async () => {
        // setup
        const {client} = this.state;

        // Get the client list and possibly client info.
        system.initialize();
        await clients.initialize();
        client.initialize();
        this.setState({loaded: true});
    }

    render() {
        // setup
        const {user, client, loaded} = this.state;

        // render
        if (!loaded) {
            return <Loading/>
        }
        return (
            <div
                className="App"
            >
                <Provider
                    system={system}
                    user={user}
                    client={client}
                >
                    <Routes>
                        <Route path="/" element={<Navigate to={'login'} />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/forgotpassword" element={<ForgotPassword />} />
                        <Route path="/reports" element={<ReportsPage />} />
                        <Route path="/engagements" element={<EngagementsPage />} />
                        <Route path="/courses" element={<CoursesPage />} />
                        <Route path="/student" element={<StudentPage />} />
                        <Route path="/support" element={<SupportPage />} />
                        <Route path="/survey" element={<SurveyPage />} />
                    </Routes>
                </Provider>
            </div>
        )
    }

}

export default App;
